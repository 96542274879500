import domReady from '@wordpress/dom-ready'
import $ from 'jquery'

domReady(function () {
	const slick = window.slick
	const sliders = $('.offer-boxes')
	// const settings = JSON.parse(sliders.attr('data-settings'))
	const settings = {
		// centerMode:   true,
		slidesToShow: 1,
		arrows:       false,
		dots:         true,
		mobileFirst:  true,
		// centerMode:    true,
		// centerPadding: '70px',
		responsive: [
			{
				breakpoint: 320,
				settings:   {
					slidesToShow: 1,
					arrow:        false,
				}
			},
			{
				breakpoint: 560,
				settings:   {
					slidesToShow:  2,
					arrow:         false,
					centerMode:    true,
					centerPadding: '70px',
				}
			},
			{
				breakpoint: 1024,
				settings:   'unslick'
			}
		]
	}
	sliders.slick(settings)
	$(window).on('resize', function () {
		sliders.slick('resize')
	})
})
